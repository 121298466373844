/*********************************/
/*             Home              */
/*===============================*/

/* Kenburn Effect */
.zoom-image {
    @apply absolute inset-0 overflow-hidden;
}

.image-wrap {
    @apply absolute -top-[350px] -bottom-[350px] -left-[100px] -right-[100px] min-w-full w-auto min-h-full h-auto overflow-hidden m-auto;
    animation: 100s ppb_kenburns linear infinite alternate;
}

@keyframes move {
    0% {
        transform-origin: bottom;
        transform: scale(1);
    }
    100% {
        transform: scale(1.4);
    }
}

@keyframes ppb_kenburns {
    0% {
        transform: scale(1.3) translate(-10%, 10%);
    }
    25% {
        transform: scale(1) translate(0, 0);
    }
    50% {
        transform: scale(1.3) translate(10%, 10%);
    }
    75% {
        transform: scale(1) translate(0, 0);
    }
    100% {
        transform: scale(1.3) translate(-10%, 10%);
    }
}