/*********************************/
/*             Helper            */
/*===============================*/
.container {
    @apply relative;
}

/* Preloader */
#preloader {
    background-image: linear-gradient(45deg, #ffffff, #ffffff);
    z-index: 99999;
    @apply fixed inset-0;
    #status {
        @apply absolute left-0 right-0 top-1/2 -translate-y-1/2;
        .spinner {
            @apply w-10 h-10 relative my-[100px] mx-auto;
            .double-bounce1, .double-bounce2 {
                @apply w-full h-full rounded-full bg-green-600/60 absolute top-0 ltr:left-0 rtl:right-0;
                animation: sk-bounce 2.0s infinite ease-in-out;
            }
            .double-bounce2 {
                animation-delay: -1.0s;
            }
        }
    }
}

@keyframes sk-bounce {
    0%, 100% { 
      transform: scale(0.0);
    } 50% { 
      transform: scale(1.0);
    }
}

/* Shapes */
.shape {
    @apply absolute right-0 -bottom-px left-0;
    &>svg {
        @apply w-full h-auto;
        transform: scale(2);
        transform-origin: theme('transformOrigin.top') theme('transformOrigin.center');
    }
}
@media (max-width: 425px) {
    .shape {
        @apply -bottom-[2px];
    }
}

/* Switcher */
.label {
    .ball {
        transition: transform 0.2s linear;
        @apply translate-x-0;
    }
}

.checkbox:checked + .label .ball {
	@apply translate-x-6;
}

/*********************************/
/*         Tobii Lightbox        */
/*===============================*/
/* Tobii Lighbox */
.tobii>button.tobii__close svg,
.tobii>button.tobii__prev svg, 
.tobii>button.tobii__next svg {
    @apply h-9 w-auto;
}

.tobii__counter {
    @apply text-base;
}

.tobii-zoom {
    @apply block;
}

.tobii-zoom__icon {
    @apply hidden;
}

#grid {
    @apply p-0;
}