@charset "UTF-8";
/* Template Name: Hously - Tailwind CSS Real Estate Template
   Author: Shreethemes
   Email: support@shreethemes.in
   Website: https://shreethemes.in
   Version: 1.3.0
   Created: June 2022
   File: Main Css File
*/
@import url("https://fonts.googleapis.com/css2?family=League+Spartan:wght@300;400;500;600;700&display=swap");
@tailwind base;
@tailwind components;
@tailwind utilities;
/* // _helper.scss */
/*********************************/
/*             Helper            */
/*===============================*/
body {
  @apply font-body text-base text-black dark:text-white;
}

p {
  @apply leading-relaxed;
}

h1, h2, h3, h4, h5, h6, .h1, .h2, .h3, .h4, .h5, .h6 {
  @apply font-body leading-normal;
}

::selection {
  @apply bg-green-600/90 text-white;
}

/********************/
/*     Buttons      */
/*==================*/
/* Note: If you want to add your own colors, you can do so by going to this tailwind.config.js file  */
.btn {
  @apply focus:outline-none text-base font-medium h-10 px-7 rounded-lg inline-flex items-center justify-center align-middle;
  /*  Button icons */
  /* Button Link */
}
.btn.btn-lg {
  @apply h-14 px-7 text-lg;
}
.btn.btn-sm {
  @apply h-7 px-4 text-sm;
}
.btn.btn-icon {
  @apply p-0 h-10 w-10 inline-flex items-center text-center justify-center text-base;
}
.btn.btn-icon.btn-lg {
  @apply h-14 w-14 text-lg;
}
.btn.btn-icon.btn-sm {
  @apply h-7 w-7;
}
.btn.btn-link {
  @apply relative h-7 p-0 border-none after:content-[""] after:absolute after:h-px after:w-0 after:right-0 after:bottom-0 after:left-0 after:transition-all after:duration-500;
}
.btn.btn-link:hover {
  @apply after:w-full ltr:after:right-auto rtl:after:left-auto;
}

/*********************************/
/*         Menu                  */
/*===============================*/
#topnav {
  @apply fixed right-0 left-0 top-0 z-999 bg-transparent border-0 transition-all duration-500 ease-in-out;
}
#topnav .logo {
  @apply ltr:float-left rtl:float-right text-black dark:text-white !important;
}
#topnav .logo .l-dark {
  @apply hidden;
}
#topnav .logo .l-light {
  @apply inline-block;
}
#topnav .logo:focus {
  @apply outline-none;
}
#topnav .has-submenu {
  @apply relative;
}
#topnav .has-submenu.active a {
  @apply text-white dark:text-slate-900;
}
#topnav .has-submenu.active .submenu li.active > a {
  @apply text-green-600 dark:text-green-600 !important;
}
#topnav .has-submenu.active.active .menu-arrow {
  @apply border-green-600 dark:border-green-600;
}
#topnav .has-submenu .submenu .submenu-arrow {
  border-width: 0px 0.125rem 0.125rem 0px;
  @apply absolute ltr:right-[1.25rem] rtl:left-[1.25rem] top-[0.8125rem] border-black dark:border-white rounded-[0.5px] inline-block p-[2px] ltr:-rotate-[45deg] rtl:rotate-[135deg];
}
#topnav .has-submenu .submenu .has-submenu:hover > .submenu-arrow {
  @apply border-green-600 dark:border-green-600;
}
#topnav .has-submenu .submenu .has-submenu .submenu .has-submenu:hover .submenu-arrow {
  @apply border-green-600 dark:border-green-600;
}
#topnav .has-submenu .submenu .has-submenu .submenu .has-submenu:hover > .submenu-arrow {
  @apply border-green-600 dark:border-green-600;
}
#topnav .navbar-toggle {
  @apply border-0 relative p-0 m-0 cursor-pointer;
}
#topnav .navbar-toggle .lines {
  @apply w-[25px] block relative h-[18px] mt-[30px] ltr:mr-0 rtl:ml-0 mb-[26px] ltr:ml-[10px] rtl:mr-[10px];
}
#topnav .navbar-toggle span {
  transition: transform 0.5s ease;
  @apply h-[2px] w-full bg-black dark:bg-white block mb-[5px];
}
#topnav .navbar-toggle span:last-child {
  @apply mb-0;
}
#topnav .navbar-toggle.open span {
  @apply absolute;
}
#topnav .navbar-toggle.open span:first-child {
  @apply top-[6px] rotate-[45deg];
}
#topnav .navbar-toggle.open span:nth-child(2) {
  @apply invisible;
}
#topnav .navbar-toggle.open span:last-child {
  @apply w-full top-[6px] -rotate-[45deg];
}
#topnav .navbar-toggle.open span:hover {
  @apply bg-green-600;
}
#topnav .navbar-toggle:hover, #topnav .navbar-toggle:focus,
#topnav .navbar-toggle .navigation-menu > li > a:hover {
  @apply bg-transparent;
}
#topnav .buy-button {
  @apply ltr:float-right rtl:float-left leading-[74px];
}
#topnav .buy-button > li {
  @apply leading-[initial];
}
#topnav .buy-button .login-btn-primary,
#topnav .buy-button .btn-icon-dark {
  @apply hidden;
}
#topnav .buy-button .login-btn-light,
#topnav .buy-button .btn-icon-light {
  @apply inline-block;
}
#topnav .buy-button .search-bar .menu-search form {
  @apply relative;
}
#topnav .buy-button .search-bar .searchform {
  @apply after:absolute after:right-[14px] after:top-[14px] after:text-xl after:leading-5 after:pointer-events-none !important;
}
#topnav .buy-button .search-bar .searchform input[type=text] {
  @apply shadow-none py-2.5 ltr:pr-[42px] rtl:pl-[42px] ltr:pl-3 rtl:pr-3 h-11 text-sm block outline-none !important;
}
#topnav .buy-button .search-bar .searchform input[type=submit] {
  @apply hidden;
}
#topnav .buy-button .search-bar .searchform:after {
  content: "󰍉";
  font-family: "Material Design Icons";
}
#topnav .buy-button .dropdown .dropdown-toggle,
#topnav .buy-menu-btn .dropdown .dropdown-toggle {
  @apply after:hidden;
}
#topnav .navigation-menu {
  @apply list-none m-0 p-0;
}
#topnav .navigation-menu > li {
  @apply ltr:float-left rtl:float-right block relative my-0 mx-[10px];
}
#topnav .navigation-menu > li > a {
  @apply block text-black dark:text-white text-base bg-transparent font-medium tracking-[1px] leading-6 font-body px-[15px];
}
#topnav .navigation-menu > li > a:hover, #topnav .navigation-menu > li > a:active {
  @apply text-green-600;
}
#topnav .navigation-menu > li:hover > a, #topnav .navigation-menu > li.active > a {
  @apply text-green-600 dark:text-green-600 !important;
}
#topnav .navigation-menu > li .submenu.megamenu li .megamenu-head {
  @apply py-[10px] px-5 whitespace-nowrap text-xs uppercase tracking-[0.04em] font-semibold text-slate-400 !important;
}
#topnav .navigation-menu .has-submenu .menu-arrow {
  border-width: 0 2px 2px 0;
  @apply border-black dark:border-white rounded-[0.5px] inline-block p-[2px] rotate-[45deg] absolute transition-all duration-500 ltr:right-0 rtl:left-0 top-8;
}
#topnav .menu-extras {
  @apply ltr:float-right rtl:float-left;
}
#topnav.scroll {
  @apply bg-white dark:bg-slate-900 border-none shadow;
}
#topnav.scroll .navigation-menu > li > a {
  @apply text-black dark:text-white;
}
#topnav.scroll .navigation-menu > li > .menu-arrow {
  @apply border-black dark:border-white;
}
#topnav.scroll .navigation-menu > li:hover > a, #topnav.scroll .navigation-menu > li.active > a {
  @apply text-green-600;
}
#topnav.scroll .navigation-menu > li:hover > .menu-arrow, #topnav.scroll .navigation-menu > li.active > .menu-arrow {
  @apply border-green-600 dark:border-green-600;
}
#topnav.defaultscroll.dark-menubar .logo {
  @apply leading-[70px];
}
#topnav.defaultscroll.scroll .logo {
  @apply leading-[62px];
}
#topnav.nav-sticky {
  @apply bg-white/80 dark:bg-slate-900/80 backdrop-blur-md shadow dark:shadow-gray-700;
}
#topnav.nav-sticky .navigation-menu.nav-light > li > a {
  @apply text-black dark:text-white;
}
#topnav.nav-sticky .navigation-menu.nav-light > li.active > a {
  @apply text-green-600 dark:text-green-600 !important;
}
#topnav.nav-sticky .navigation-menu.nav-light > li:hover > .menu-arrow, #topnav.nav-sticky .navigation-menu.nav-light > li.active > .menu-arrow {
  @apply border-green-600 !important;
}
#topnav.nav-sticky .navigation-menu.nav-light > li:hover > a, #topnav.nav-sticky .navigation-menu.nav-light > li.active > a {
  @apply text-green-600 dark:text-green-600 !important;
}
#topnav.nav-sticky .navigation-menu.nav-light .has-submenu .menu-arrow {
  @apply border-black dark:border-white;
}
#topnav.nav-sticky.tagline-height {
  @apply top-0 !important;
}
#topnav.nav-sticky .buy-button .login-btn-primary,
#topnav.nav-sticky .buy-button .btn-icon-dark {
  @apply inline-block;
}
#topnav.nav-sticky .buy-button .login-btn-light,
#topnav.nav-sticky .buy-button .btn-icon-light {
  @apply hidden;
}
#topnav.nav-sticky .logo .l-dark {
  @apply inline-block;
}
#topnav.nav-sticky .logo .l-light {
  @apply hidden;
}

.logo {
  @apply font-semibold text-[24px] ltr:mr-[15px] rtl:ml-[15px] pt-0 pb-0 tracking-[1px] leading-[68px];
}

@media (min-width: 1025px) {
  #topnav .navigation-menu > li .submenu.megamenu {
    @apply w-[1116px] !important;
  }
}
@media screen and (max-width: 1024px) and (min-width: 992px) {
  #topnav .navigation-menu > li .submenu.megamenu {
    @apply w-[936px] !important;
  }
}
@media (min-width: 992px) {
  #topnav .navigation-menu {
    @apply flex flex-wrap justify-center;
  }
  #topnav .navigation-menu > li .submenu {
    transition: all 0.2s ease;
    @apply absolute top-full ltr:left-0 rtl:right-0 z-[1000] py-[15px] px-0 list-none min-w-[180px] invisible opacity-0 mt-[10px] rounded-md bg-white dark:bg-slate-900 shadow dark:shadow-gray-700;
  }
  #topnav .navigation-menu > li .submenu li {
    @apply relative;
  }
  #topnav .navigation-menu > li .submenu li a {
    transition: all 0.3s;
    @apply block py-[10px] px-5 clear-both whitespace-nowrap text-[16px] tracking-[0.04em] font-normal text-black dark:text-white leading-[17px];
  }
  #topnav .navigation-menu > li .submenu li a:hover {
    @apply text-green-600 dark:text-green-600 !important;
  }
  #topnav .navigation-menu > li .submenu li ul {
    @apply list-none ltr:pl-0 rtl:pr-0 m-0;
  }
  #topnav .navigation-menu > li .submenu.megamenu {
    @apply whitespace-nowrap ltr:left-1/2 ltr:-translate-x-1/2 rtl:right-1/2 rtl:translate-x-1/2 fixed top-auto flex;
  }
  #topnav .navigation-menu > li .submenu.megamenu > li {
    @apply overflow-hidden align-top w-1/5;
  }
  #topnav .navigation-menu > li .submenu.megamenu > li .submenu {
    @apply ltr:left-full rtl:right-full top-0 ltr:ml-[10px] rtl:mr-[10px] -mt-px;
  }
  #topnav .navigation-menu > li .submenu > li .submenu {
    @apply ltr:left-[101%] rtl:right-[101%] top-0 ltr:ml-[10px] rtl:mr-[10px] -mt-px;
  }
  #topnav .navigation-menu > li > a {
    @apply py-[25px] min-h-[62px];
  }
  #topnav .navigation-menu > li:hover > .menu-arrow {
    @apply border-green-600 dark:border-green-600;
  }
  #topnav .navigation-menu > li:hover > a, #topnav .navigation-menu > li.active > a {
    @apply text-green-600 dark:text-green-600 !important;
  }
  #topnav .navigation-menu > li.last-elements .submenu {
    @apply ltr:left-auto rtl:right-auto right-0 ltr:before:left-auto rtl:before:right-auto ltr:before:right-[10px] rtl:before:left-[10px];
  }
  #topnav .navigation-menu > li.last-elements .submenu > li.has-submenu .submenu {
    @apply ltr:left-auto rtl:right-auto ltr:right-full rtl:left-full ltr:ml-0 rtl:mr-0 ltr:mr-[10px] rtl:ml-[10px];
  }
  #topnav .navigation-menu.nav-light > li > a {
    @apply text-white/50;
  }
  #topnav .navigation-menu.nav-light > li.active > a {
    @apply text-white !important;
  }
  #topnav .navigation-menu.nav-light > li:hover > .menu-arrow {
    @apply border-white !important;
  }
  #topnav .navigation-menu.nav-light > li:hover > a {
    @apply text-white !important;
  }
  #topnav .navigation-menu.nav-light .has-submenu .menu-arrow {
    @apply border-white/50;
  }
  #topnav .navigation-menu.nav-light .has-submenu.active .menu-arrow {
    @apply border-white !important;
  }
  #topnav .buy-button {
    @apply ltr:pl-[15px] rtl:pr-[15px] ltr:ml-[15px] rtl:mr-[15px];
  }
  #topnav .navbar-toggle {
    @apply hidden;
  }
  #topnav #navigation {
    @apply block !important;
  }
  #topnav.scroll {
    @apply top-0;
  }
  #topnav.scroll .navigation-menu > li > a {
    @apply py-5;
  }
  #topnav.scroll-active .navigation-menu > li > a {
    @apply py-[25px];
  }
}
@media (max-width: 991px) {
  #topnav {
    @apply bg-white dark:bg-slate-900 shadow dark:shadow-gray-700 min-h-[74px];
  }
  #topnav .logo .l-dark {
    @apply inline-block !important;
  }
  #topnav .logo .l-light {
    @apply hidden !important;
  }
  #topnav .container {
    @apply w-auto;
  }
  #topnav #navigation {
    @apply max-h-[400px] shadow;
  }
  #topnav .navigation-menu {
    @apply float-none !important;
  }
  #topnav .navigation-menu > li {
    @apply float-none !important;
  }
  #topnav .navigation-menu > li .submenu {
    @apply hidden list-none ltr:pl-5 rtl:pr-5 m-0;
  }
  #topnav .navigation-menu > li .submenu li a {
    transition: all 0.3s;
    @apply block relative py-[7px] px-[15px] tracking-[0.04em] text-black dark:text-white !important;
  }
  #topnav .navigation-menu > li .submenu.megamenu li .megamenu-head {
    @apply py-[7px] px-[15px];
  }
  #topnav .navigation-menu > li .submenu.megamenu > li > ul {
    @apply list-none ltr:pl-0 rtl:pr-0;
  }
  #topnav .navigation-menu > li .submenu.megamenu > li > ul > li > span {
    @apply block relative py-[10px] px-[15px] uppercase text-xs tracking-[2px] text-slate-200;
  }
  #topnav .navigation-menu > li .submenu.open {
    @apply block;
  }
  #topnav .navigation-menu > li .submenu .submenu {
    @apply hidden list-none;
  }
  #topnav .navigation-menu > li .submenu .submenu.open {
    @apply block;
  }
  #topnav .navigation-menu > li > a {
    @apply text-black dark:text-white py-[10px] px-[20px] after:absolute ltr:after:right-[15px] rtl:after:left-[15px];
  }
  #topnav .navigation-menu > li > a:hover,
#topnav .navigation-menu > li .submenu li a:hover, #topnav .navigation-menu > li.has-submenu.open > a {
    @apply text-green-600;
  }
  #topnav .menu-extras .menu-item {
    @apply border-gray-200 dark:border-gray-700;
  }
  #topnav .navbar-header {
    @apply ltr:float-left rtl:float-right;
  }
  #topnav .buy-button .login-btn-primary,
#topnav .buy-button .btn-icon-dark {
    @apply inline-block !important;
  }
  #topnav .buy-button .login-btn-light,
#topnav .buy-button .btn-icon-light {
    @apply hidden;
  }
  #topnav .has-submenu .submenu .submenu-arrow {
    @apply rotate-[45deg] absolute ltr:right-5 rtl:left-5 top-3;
  }
  #topnav .has-submenu.active a {
    @apply text-green-600;
  }

  #navigation {
    @apply absolute top-[74px] ltr:left-0 rtl:right-0 w-full hidden h-auto bg-white dark:bg-slate-900 overflow-auto;
  }
  #navigation.open {
    @apply block overflow-y-auto;
  }
}
@media (max-width: 768px) {
  #topnav .navigation-menu .has-submenu .menu-arrow {
    @apply ltr:right-2 rtl:left-2 top-4;
  }
}
@media (min-width: 768px) {
  #topnav .navigation-menu > li.has-submenu:hover > .submenu {
    @apply visible opacity-100 mt-0;
  }
  #topnav .navigation-menu > li.has-submenu:hover > .submenu > li.has-submenu:hover > .submenu {
    @apply visible opacity-100 ml-0 mr-0;
  }
  #topnav .navigation-menu > li.has-submenu:hover > .submenu > li.has-submenu:hover > .submenu > li:hover > .submenu {
    @apply visible opacity-100 ml-0 mr-0;
  }

  .navbar-toggle {
    @apply block;
  }
}
@media (max-width: 425px) {
  #topnav .buy-menu-btn {
    @apply block my-0 mx-[10px] py-[10px] px-5 !important;
  }
  #topnav .buy-menu-btn .dropdown .dropdown-menu.show {
    transform: translate3d(0px, -54px, 0px) !important;
  }
}
.tagline {
  @apply absolute w-full z-[99] text-sm py-[13px] px-0;
}

@media screen and (max-width: 575px) {
  .tagline {
    @apply hidden;
  }

  .tagline-height {
    @apply top-0 !important;
  }
}
@media (min-width: 576px) {
  .tagline-height {
    @apply top-[47px] !important;
  }
}
.sidebar-nav {
  @apply py-[15px] px-0;
}
.sidebar-nav > .navbar-item {
  @apply py-[3px] px-[15px];
}
.sidebar-nav > .navbar-item .navbar-link {
  @apply text-[15px] font-semibold;
}
.sidebar-nav > .navbar-item .navbar-link .navbar-icon {
  @apply text-lg ltr:mr-[6px] rtl:ml-[6px];
}
.sidebar-nav > .navbar-item:hover .navbar-link, .sidebar-nav > .navbar-item.active .navbar-link {
  @apply text-green-600;
}
.sidebar-nav li.active a {
  @apply text-green-600;
}
.sidebar-nav li.account-menu.active .navbar-link, .sidebar-nav li.account-menu:hover .navbar-link {
  @apply bg-green-600 text-white dark:text-slate-900 !important;
}

/* // _footer.scss */
/*********************************/
/*         Footer                */
/*===============================*/
.footer .foot-subscribe input::placeholder {
  @apply text-gray-200;
}

/*********************************/
/*             Helper            */
/*===============================*/
.container {
  @apply relative;
}

/* Preloader */
#preloader {
  background-image: linear-gradient(45deg, #ffffff, #ffffff);
  z-index: 99999;
  @apply fixed inset-0;
}
#preloader #status {
  @apply absolute left-0 right-0 top-1/2 -translate-y-1/2;
}
#preloader #status .spinner {
  @apply w-10 h-10 relative my-[100px] mx-auto;
}
#preloader #status .spinner .double-bounce1, #preloader #status .spinner .double-bounce2 {
  @apply w-full h-full rounded-full bg-green-600/60 absolute top-0 ltr:left-0 rtl:right-0;
  animation: sk-bounce 2s infinite ease-in-out;
}
#preloader #status .spinner .double-bounce2 {
  animation-delay: -1s;
}

@keyframes sk-bounce {
  0%, 100% {
    transform: scale(0);
  }
  50% {
    transform: scale(1);
  }
}
/* Shapes */
.shape {
  @apply absolute right-0 -bottom-px left-0;
}
.shape > svg {
  @apply w-full h-auto;
  transform: scale(2);
  transform-origin: theme("transformOrigin.top") theme("transformOrigin.center");
}

@media (max-width: 425px) {
  .shape {
    @apply -bottom-[2px];
  }
}
/* Switcher */
.label .ball {
  transition: transform 0.2s linear;
  @apply translate-x-0;
}

.checkbox:checked + .label .ball {
  @apply translate-x-6;
}

/*********************************/
/*         Tobii Lightbox        */
/*===============================*/
/* Tobii Lighbox */
.tobii > button.tobii__close svg,
.tobii > button.tobii__prev svg,
.tobii > button.tobii__next svg {
  @apply h-9 w-auto;
}

.tobii__counter {
  @apply text-base;
}

.tobii-zoom {
  @apply block;
}

.tobii-zoom__icon {
  @apply hidden;
}

#grid {
  @apply p-0;
}

/*********************************/
/*             Home              */
/*===============================*/
/* Kenburn Effect */
.zoom-image {
  @apply absolute inset-0 overflow-hidden;
}

.image-wrap {
  @apply absolute -top-[350px] -bottom-[350px] -left-[100px] -right-[100px] min-w-full w-auto min-h-full h-auto overflow-hidden m-auto;
  animation: 100s ppb_kenburns linear infinite alternate;
}

@keyframes move {
  0% {
    transform-origin: bottom;
    transform: scale(1);
  }
  100% {
    transform: scale(1.4);
  }
}
@keyframes ppb_kenburns {
  0% {
    transform: scale(1.3) translate(-10%, 10%);
  }
  25% {
    transform: scale(1) translate(0, 0);
  }
  50% {
    transform: scale(1.3) translate(10%, 10%);
  }
  75% {
    transform: scale(1) translate(0, 0);
  }
  100% {
    transform: scale(1.3) translate(-10%, 10%);
  }
}
/*********************************/
/*         Testimonial           */
/*===============================*/
.client-testi {
  @apply cursor-e-resize;
}
.client-testi .content {
  @apply before:content-[""] before:absolute before:top-8 before:left-0 before:ml-3 before:box-border before:border-8 before:border-solid before:origin-[0] before:rotate-[135deg] before:shadow before:border-t-transparent before:border-r-white dark:before:border-r-slate-900 before:border-b-white dark:before:border-b-slate-900 before:border-l-transparent;
}

.customer-testi {
  @apply cursor-e-resize;
}
.customer-testi .content {
  @apply before:content-[""] before:absolute before:left-1/2 before:-bottom-[4px] before:box-border before:border-8 before:rotate-[45deg] before:border-t-transparent before:border-r-white dark:before:border-r-slate-900 before:border-b-white dark:before:border-b-slate-900 before:border-l-transparent before:shadow-testi dark:before:shadow-gray-700;
}
.customer-testi .content:before {
  transform-origin: 0 0;
}

.tns-nav {
  @apply text-center mt-3;
}
.tns-nav button {
  @apply rounded-full bg-black/20 dark:bg-white/20 transition-all duration-500 ease-in-out border-2 border-white dark:border-slate-900 m-1 p-[2px];
}
.tns-nav button.tns-nav-active {
  @apply bg-green-600 border-green-600;
}

/* Tns control */
.tns-controls button[data-controls=prev],
.tns-controls button[data-controls=next] {
  @apply absolute top-2/4 -translate-y-2/4 text-base w-8 h-8 rounded-full bg-white dark:bg-slate-900 text-dark dark:text-white border-0 transition-all duration-500 ease-in-out z-10 shadow-md dark:shadow-gray-700;
}
.tns-controls button[data-controls=prev]:hover,
.tns-controls button[data-controls=next]:hover {
  @apply bg-green-600 text-white;
}
.tns-controls button[data-controls=prev] {
  @apply left-0;
}
.tns-controls button[data-controls=next] {
  @apply right-0;
}

/* RTL */
html[dir=rtl] .tns-inner, html[dir=rtl] .tns-nav {
  direction: ltr !important;
}
html[dir=rtl] .tns-visually-hidden {
  left: 0 !important;
  visibility: hidden;
}

/*********************************/
/*           Choices             */
/*===============================*/
.filter-search-form .icons {
  @apply absolute top-[48%] -translate-y-1/2 ltr:left-3 rtl:right-3 z-1 text-green-600 text-[20px];
}

.registration-form {
  @apply relative;
}
.registration-form .submit-btn {
  @apply rounded-lg;
}
.registration-form .form-input,
.registration-form .choices[data-type*=select-one] .choices__inner {
  @apply rounded-lg;
}

@media (min-width: 992px) {
  .filter-border {
    @apply before:content-[""] before:absolute before:top-[10px] ltr:before:right-0 rtl:before:left-0 before:h-10 before:z-1 before:border-r before:border-inherit before:rounded-md before:outline-0 dark:before:border-gray-700;
  }

  .registration-form {
    @apply relative;
  }
  .registration-form .submit-btn {
    @apply rounded-t-none ltr:rounded-r-sm rtl:rounded-l-sm rounded-b-sm ltr:rounded-l-none rtl:rounded-r-none;
  }
  .registration-form .form-input {
    @apply rounded-t-sm ltr:rounded-r-none rtl:rounded-l-none rounded-b-none ltr:rounded-l-sm rtl:rounded-r-sm outline-0;
  }
  .registration-form .choices[data-type*=select-one] .choices__inner {
    @apply rounded-none;
  }
}
.filter-input-box,
.filter-input-box.form-select,
.filter-search-form .choices__inner {
  @apply shadow-none border-0 rounded-none text-[15px] h-[60px] pt-[13px] ltr:pr-[6px] rtl:pl-[6px] pb-[15px] ltr:pl-[45px] rtl:pr-[45px] rtl:text-right;
}

.choices__inner {
  @apply bg-gray-50 dark:bg-slate-800;
}

.choices__list--dropdown,
.choices[data-type*=select-one] .choices__input {
  @apply bg-white dark:bg-slate-900 border-0 rounded-[5px] shadow dark:shadow-gray-800 z-999;
}

.choices__list--dropdown .choices__item--selectable.is-highlighted {
  @apply bg-gray-50 dark:bg-slate-800 text-black dark:text-white;
}

.choices__list,
.choices__list--dropdown .choices__item--selectable {
  @apply text-slate-400;
}

.choices__list--dropdown,
.choices__list[aria-expanded] {
  @apply border-0 z-2;
}

.choices[data-type*=select-one] {
  @apply after:border-0 after:top-5 ltr:after:right-7 ltr:after:left-auto rtl:after:left-7 rtl:after:right-auto after:text-slate-900 dark:after:text-white;
}
.choices[data-type*=select-one]:after {
  content: "󰅀" !important;
  font-family: "Material Design Icons";
}

/*********************************/
/*         Countdown             */
/*===============================*/
#countdown .count-down .count-number {
  @apply text-[40px] leading-[110px] h-[130px] w-[130px] rounded-full shadow-md bg-white/10;
  backdrop-filter: blur(5px);
}
#countdown .count-down .count-head {
  @apply relative block -translate-y-[25px] uppercase text-sm tracking-[1px];
}

/* //maintenance */
#maintenance .indicator {
  @apply text-lg;
}

/*********************************/
/*             Contact           */
/*===============================*/
.form-input {
  @apply w-full py-2 px-3 border border-inherit dark:border-gray-800 dark:bg-slate-900 dark:text-slate-200 rounded h-10 outline-none bg-transparent;
}
.form-input:focus {
  @apply border-green-600 shadow-none;
}
.form-input.textarea {
  @apply h-28;
}

/* Validation */
.error {
  @apply my-2 mx-0 hidden text-red-600;
}

#ajaxsuccess {
  @apply text-base w-full hidden clear-both my-2 mx-0;
}

.error_message,
#success_page {
  @apply p-2.5 mb-5 text-center rounded-md;
}

.error_message {
  @apply bg-red-600/5 text-red-600;
}

.contact-loader {
  @apply hidden;
}

#success_page {
  @apply bg-green-600/5 text-green-600;
}

/*****************/
/* Subscribe Form */
/*****************/
.subcribe-form form input {
  @apply pt-4 ltr:pr-40 rtl:pl-40 pb-4 ltr:pl-6 rtl:pr-6 w-full h-[50px] outline-none text-black dark:text-white;
}
.subcribe-form form .btn {
  @apply absolute top-[2px] ltr:right-[3px] rtl:left-[3px] h-[46px];
}

/*****************/
/* Job search form */
/*****************/
.filter-input-box,
.filter-input-box.form-select,
.filter-search-form .choices__inner {
  @apply shadow-none border-0 rounded-none text-[15px] h-[60px] pt-[13px] ltr:pr-[6px] rtl:pl-[6px] pb-[15px] ltr:pl-[45px] rtl:pr-[45px];
}