

/********************/
/*     Buttons      */
/*==================*/
/* Note: If you want to add your own colors, you can do so by going to this tailwind.config.js file  */
.btn {
    @apply focus:outline-none text-base font-medium h-10 px-7 rounded-lg inline-flex items-center justify-center align-middle;

    &.btn-lg{
        @apply h-14 px-7 text-lg;
    }
    &.btn-sm{
        @apply h-7 px-4 text-sm;
    }
    /*  Button icons */
    &.btn-icon {
        @apply p-0 h-10 w-10 inline-flex items-center text-center justify-center text-base;
        &.btn-lg {
            @apply h-14 w-14 text-lg;
        }
        &.btn-sm {
            @apply h-7 w-7;
        }
    }

    /* Button Link */
    &.btn-link {
        @apply relative h-7 p-0 border-none after:content-[''] after:absolute after:h-px after:w-0 after:right-0 after:bottom-0 after:left-0 after:transition-all after:duration-500;
    
        &:hover {
            @apply after:w-full ltr:after:right-auto rtl:after:left-auto;
        }
    }
}