/*********************************/
/*         Menu                  */
/*===============================*/
#topnav {
    @apply fixed right-0 left-0 top-0 z-999 bg-transparent border-0 transition-all duration-500 ease-in-out;
    .logo {
        @apply ltr:float-left rtl:float-right text-black dark:text-white #{!important};
        .l-dark {
            @apply hidden;
        }
        .l-light {
            @apply inline-block;
        }
        &:focus {
          @apply outline-none;
        }
    }
    .has-submenu{
      @apply relative;
      &.active {
        a {
          @apply text-white dark:text-slate-900;
        }
        .submenu {
          li{
            &.active {
              > a {
                @apply text-green-600 dark:text-green-600 #{!important};
              }
            }
          }
        }
        &.active {
          .menu-arrow {
            @apply border-green-600 dark:border-green-600;
          }
        }
      }
      .submenu {
        .submenu-arrow {
          border-width: 0px 0.125rem 0.125rem 0px;
          @apply absolute ltr:right-[1.25rem] rtl:left-[1.25rem] top-[0.8125rem] border-black dark:border-white rounded-[0.5px] inline-block p-[2px] ltr:-rotate-[45deg] rtl:rotate-[135deg];
        }
        .has-submenu {
          &:hover {
            > .submenu-arrow {
              @apply border-green-600 dark:border-green-600;
            }
          }
          .submenu {
            .has-submenu{
              &:hover {
                .submenu-arrow {
                  @apply border-green-600 dark:border-green-600;
                }
                > .submenu-arrow {
                  @apply border-green-600 dark:border-green-600;
                }
              }
            }
          }
        }
      }
    }
    .navbar-toggle {
      @apply border-0 relative p-0 m-0 cursor-pointer;
      .lines {
        @apply w-[25px] block relative h-[18px] mt-[30px] ltr:mr-0 rtl:ml-0 mb-[26px] ltr:ml-[10px] rtl:mr-[10px];
      }
      span {
        transition: transform 0.5s ease;
        @apply h-[2px] w-full bg-black dark:bg-white block mb-[5px];
        &:last-child {
          @apply mb-0;
        }
      }
      &.open {
        span {
          @apply absolute;
          &:first-child {
            @apply top-[6px] rotate-[45deg];
          }
          &:nth-child(2) {
            @apply invisible;
          }
          &:last-child {
            @apply w-full top-[6px] -rotate-[45deg];
          }
          &:hover {
            @apply bg-green-600;
          }
        }
      }
      &:hover, &:focus,
      .navigation-menu > li > a:hover {
        @apply bg-transparent;
      }
    }
    .buy-button {
      @apply ltr:float-right rtl:float-left leading-[74px];
      > li {
        @apply leading-[initial];
      }
      .login-btn-primary,
      .btn-icon-dark {
        @apply hidden;
      }
      .login-btn-light,
      .btn-icon-light {
        @apply inline-block;
      }
      .search-bar {
        .menu-search {
          form {
            @apply relative;
          }
        }
        .searchform {
          @apply after:absolute after:right-[14px] after:top-[14px] after:text-xl after:leading-5 after:pointer-events-none #{!important};
          input[type=text] {
            @apply shadow-none py-2.5 ltr:pr-[42px] rtl:pl-[42px] ltr:pl-3 rtl:pr-3 h-11 text-sm block outline-none #{!important};
          }
          input[type=submit] {
            @apply hidden;
          }
          &:after {
            content: "\f0349";
            font-family: "Material Design Icons";
          }
        }
      }
    }
    .buy-button,
    .buy-menu-btn {
      .dropdown {
        .dropdown-toggle {
          @apply after:hidden;
        }
      }
    }
    .navigation-menu {
      @apply list-none m-0 p-0;
      > li {
        @apply ltr:float-left rtl:float-right block relative my-0 mx-[10px];
        > a {
          @apply block text-black dark:text-white text-base bg-transparent font-medium tracking-[1px] leading-6 font-body px-[15px];
          &:hover, 
          &:active {
            @apply text-green-600;
          }
        }
        &:hover, 
        &.active{
          > a {
            @apply text-green-600 dark:text-green-600 #{!important};
          }
        }
        .submenu.megamenu {
          li {
            .megamenu-head {
              @apply py-[10px] px-5 whitespace-nowrap text-xs uppercase tracking-[0.04em] font-semibold text-slate-400 #{!important};
            }
          }
        }
      }
      .has-submenu {
        .menu-arrow {
          border-width: 0 2px 2px 0;
          @apply border-black dark:border-white rounded-[0.5px] inline-block p-[2px] rotate-[45deg] absolute transition-all duration-500 ltr:right-0 rtl:left-0 top-8;
        }
      }
    }
    .menu-extras {
      @apply ltr:float-right rtl:float-left;
    }
    &.scroll {
      @apply bg-white dark:bg-slate-900 border-none shadow;
      .navigation-menu {
        > li {
          > a {
            @apply text-black dark:text-white;
          }
          > .menu-arrow {
            @apply border-black dark:border-white;
          }
          &:hover, 
          &.active{
            > a {
              @apply text-green-600;
            }
            > .menu-arrow {
              @apply border-green-600 dark:border-green-600;
            }
          }
        }
      }
    }
    &.defaultscroll{
      &.dark-menubar {
        .logo {
          @apply leading-[70px];
        }
      }
      &.scroll {
        .logo {
          @apply leading-[62px];
        }
      }
    }
    &.nav-sticky {
      @apply bg-white/80 dark:bg-slate-900/80 backdrop-blur-md shadow dark:shadow-gray-700;
      .navigation-menu{
        &.nav-light {
          > li {
            > a {
              @apply text-black dark:text-white;
            }
            &.active {
              > a {
                @apply text-green-600 dark:text-green-600 #{!important};
              }
            }
            &:hover, 
            &.active{
              > .menu-arrow {
                @apply border-green-600 #{!important};
              }
            }
            
            &:hover, 
            &.active {
              > a {
                @apply text-green-600 dark:text-green-600 #{!important};
              }
            }
          }
          .has-submenu {
            .menu-arrow {
              @apply border-black dark:border-white;
            }
          }
        }
      }
      &.tagline-height {
        @apply top-0 #{!important};
      }
      .buy-button {
        .login-btn-primary,
        .btn-icon-dark {
          @apply inline-block;
        }
        .login-btn-light,
        .btn-icon-light {
          @apply hidden;
        }
      }
      
      .logo {
        .l-dark {
          @apply inline-block;
        }
        .l-light {
          @apply hidden;
        }
    }
  }
}

.logo {
  @apply font-semibold text-[24px] ltr:mr-[15px] rtl:ml-[15px] pt-0 pb-0 tracking-[1px] leading-[68px];
}

@media (min-width: 1025px) {
  #topnav {
    .navigation-menu {
      > li {
        .submenu{
          &.megamenu {
            @apply w-[1116px] #{!important};
          }
        }
      }
    }
  }
}

@media screen and (max-width: 1024px) and (min-width: 992px) {
  #topnav {
    .navigation-menu {
      > li {
        .submenu{
          &.megamenu {
            @apply w-[936px] #{!important};
          }
        }
      }
    }
  }
}

@media (min-width: 992px) {
  #topnav {
    .navigation-menu {
      @apply flex flex-wrap justify-center;
      > li {
        .submenu {
          transition: all 0.2s ease;
          @apply absolute top-full ltr:left-0 rtl:right-0 z-[1000] py-[15px] px-0 list-none min-w-[180px] invisible opacity-0 mt-[10px] rounded-md bg-white dark:bg-slate-900 shadow dark:shadow-gray-700;
          li {
            @apply relative;
            a {
              transition: all 0.3s;
              @apply block py-[10px] px-5 clear-both whitespace-nowrap text-[16px] tracking-[0.04em] font-normal text-black dark:text-white leading-[17px];
              &:hover {
                @apply text-green-600 dark:text-green-600 #{!important};
              }
            }
            ul {
              @apply list-none ltr:pl-0 rtl:pr-0 m-0;
            }
          }
          &.megamenu {
            @apply whitespace-nowrap ltr:left-1/2 ltr:-translate-x-1/2 rtl:right-1/2 rtl:translate-x-1/2 fixed top-auto flex;
            > li {
              @apply overflow-hidden align-top w-1/5;
              .submenu {
                @apply ltr:left-full rtl:right-full top-0 ltr:ml-[10px] rtl:mr-[10px] -mt-px;
              }
            }
          }
          > li {
            .submenu {
              @apply ltr:left-[101%] rtl:right-[101%] top-0 ltr:ml-[10px] rtl:mr-[10px] -mt-px;
            }
          }
        }
        > a {
          @apply py-[25px] min-h-[62px];
        }
        &:hover {
          > .menu-arrow {
            @apply border-green-600 dark:border-green-600;
          }
        }
        &:hover, 
        &.active{
          > a {
            @apply text-green-600 dark:text-green-600 #{!important};
          }
        }
        &.last-elements{
          .submenu {
            @apply ltr:left-auto rtl:right-auto right-0 ltr:before:left-auto rtl:before:right-auto ltr:before:right-[10px] rtl:before:left-[10px];
            > li{
              &.has-submenu {
                .submenu {
                  @apply ltr:left-auto rtl:right-auto ltr:right-full rtl:left-full ltr:ml-0 rtl:mr-0 ltr:mr-[10px] rtl:ml-[10px];
                }
              }
            }
          }
        }
      }
      &.nav-light {
        > li {
          > a {
            @apply text-white/50;
          }
          &.active {
            > a {
              @apply text-white #{!important};
            }
          }
          &:hover {
            > .menu-arrow {
              @apply border-white #{!important};
            }
            > a {
              @apply text-white #{!important};
            }
          }
        }
        .has-submenu {
          .menu-arrow {
            @apply border-white/50;
          }
          &.active {
            .menu-arrow {
              @apply border-white #{!important};
            }
          }
        }
      }
    }
    .buy-button {
      @apply ltr:pl-[15px] rtl:pr-[15px] ltr:ml-[15px] rtl:mr-[15px];
    }
    .navbar-toggle {
      @apply hidden;
    }
    #navigation {
      @apply block #{!important};
    }
    &.scroll {
      @apply top-0;
      .navigation-menu {
        > li {
          > a {
            @apply py-5;
          }
        }
      }
    }
    &.scroll-active {
      .navigation-menu {
        > li {
          > a {
            @apply py-[25px];
          }
        }
      }
    }
  }
}

@media (max-width: 991px) {
  #topnav {
    @apply bg-white dark:bg-slate-900 shadow dark:shadow-gray-700 min-h-[74px];
    .logo {
      .l-dark {
        @apply inline-block #{!important};
      }
      .l-light {
        @apply hidden #{!important};
      }
    }
    .container {
      @apply w-auto;
    }
    #navigation {
      @apply max-h-[400px] shadow;
    }
    .navigation-menu {
      @apply float-none #{!important};
      > li {
        @apply float-none #{!important};
        .submenu {
          @apply hidden list-none ltr:pl-5 rtl:pr-5 m-0;
          li {
            a {
              transition: all 0.3s;
              @apply block relative py-[7px] px-[15px] tracking-[0.04em] text-black dark:text-white #{!important};
            }
          }
          &.megamenu {
            li {
              .megamenu-head {
                @apply py-[7px] px-[15px];
              }
            }
            > li {
              > ul {
                @apply list-none ltr:pl-0 rtl:pr-0;
                > li {
                  > span {
                    @apply block relative py-[10px] px-[15px] uppercase text-xs tracking-[2px] text-slate-200;
                  }
                }
              }
            }
          }
          &.open {
            @apply block;
          }
          .submenu {
            @apply hidden list-none;
            &.open {
              @apply block;
            }
          }
        }
        > a {
          @apply text-black dark:text-white py-[10px] px-[20px] after:absolute ltr:after:right-[15px] rtl:after:left-[15px];
        }
        > a:hover,
        .submenu li a:hover,
        &.has-submenu.open > a {
          @apply text-green-600;
        }
      }
    }
    .menu-extras {
      .menu-item {
        @apply border-gray-200 dark:border-gray-700;
      }
    }
    .navbar-header {
      @apply ltr:float-left rtl:float-right;
    }

    .buy-button {
      .login-btn-primary,
      .btn-icon-dark {
        @apply inline-block #{!important};
      }
      .login-btn-light,
      .btn-icon-light {
        @apply hidden;
      }
    }
    .has-submenu {
      .submenu {
        .submenu-arrow {
          @apply rotate-[45deg] absolute ltr:right-5 rtl:left-5 top-3;
        }
      }
      &.active {
        a {
          @apply text-green-600;
        }
      }
    }
  }
  
  #navigation {
    @apply absolute top-[74px] ltr:left-0 rtl:right-0 w-full hidden h-auto bg-white dark:bg-slate-900 overflow-auto;
    &.open {
      @apply block overflow-y-auto;
    }
  }
}

@media (max-width: 768px) {
  #topnav {
    .navigation-menu {
      .has-submenu {
        .menu-arrow {
          @apply ltr:right-2 rtl:left-2 top-4;
        }
      }
    }
  }
}

@media (min-width: 768px) {
  #topnav {
    .navigation-menu {
      > li{
        &.has-submenu{
          &:hover {
            > .submenu {
              @apply visible opacity-100 mt-0;
              > li{
                &.has-submenu{
                  &:hover {
                    > .submenu {
                      @apply visible opacity-100 ml-0 mr-0;
                      > li{
                        &:hover {
                          > .submenu {
                            @apply visible opacity-100 ml-0 mr-0;
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
  .navbar-toggle {
    @apply block;
  }
}

@media (max-width: 425px) {
  #topnav {
    .buy-menu-btn {
      @apply block my-0 mx-[10px] py-[10px] px-5 #{!important};
      .dropdown {
        .dropdown-menu{
          &.show {
            transform: translate3d(0px, -54px, 0px) !important;
          }
        }
      }
    }
  }
}
.tagline {
    @apply absolute w-full z-[99] text-sm py-[13px] px-0;
}
@media screen and (max-width: 575px) {
    .tagline {
        @apply hidden;
    }
    .tagline-height {
        @apply top-0 #{!important}; 
    }
}
@media (min-width: 576px) {
  .tagline-height {
    @apply top-[47px] #{!important};
  }
}
  
.sidebar-nav {
    @apply py-[15px] px-0;
        > .navbar-item {
          @apply py-[3px] px-[15px];
          .navbar-link {
            @apply text-[15px] font-semibold;
            .navbar-icon {
              @apply text-lg ltr:mr-[6px] rtl:ml-[6px];
            }
          }
          &:hover,
          &.active {
            .navbar-link {
              @apply text-green-600;
            }
          }
    }
    li{
      &.active {
        a {
          @apply text-green-600;
        }
      }
      &.account-menu.active,
      &.account-menu:hover{
        .navbar-link {
          @apply bg-green-600 text-white dark:text-slate-900 #{!important};
        }
      }
    }
}